const escapeHtml = (text) => {
  if (!text) {
    return undefined;
  }
  const dirtyTextArray = text.split(/<br\/>|<\/p>|\n/);
  const cleanTextArray = dirtyTextArray
    .map((message) => {
      const doc = new DOMParser().parseFromString(message, 'text/html');
      const escapedText = doc.body.textContent;
      // eslint-disable-next-line
      return !!escapedText ? escapedText : undefined;
    })
    .filter((message) => !!message);
  return cleanTextArray;
};

export {
  // eslint-disable-next-line
  escapeHtml,
};

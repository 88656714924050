import getRange from './getRange';

const getTime12Format = (time) => {
  if (time.toLowerCase().includes('m')) {
    return time;
  }
  const [h, m] = time.split(':');
  if (h < 12) {
    return `${Number(h) || 12}:${m}am`;
  } if (Number(h) === 12) {
    return `${12}:${m}pm`;
  }
  return `${(Number(h) || 13) - 12}:${m}pm`;
};

export default (from, to) => {
  const from12 = getTime12Format(from);
  const to12 = getTime12Format(to);
  return getRange(from12, to12);
};

export default (place) => {
  if (!place?.address_components) return undefined;

  const streetNumber = place.address_components.filter((ac) => ac.types.indexOf('street_number') > -1)[0];
  const street = place.address_components.filter((ac) => ac.types.indexOf('route') > -1)[0];
  const state = place.address_components.filter((ac) => ac.types.indexOf('administrative_area_level_1') > -1)[0];
  const city = place.address_components.filter((ac) => ac.types.indexOf('locality') > -1)[0];
  const zip = place.address_components.filter((ac) => ac.types.indexOf('postal_code') > -1)[0];

  const preciseLocation = place.address_components.filter((ac) => ac.types.indexOf('route') > -1).length > 0;

  let targetAddress = place.formatted_address;
  if (targetAddress.endsWith(', USA')) {
    targetAddress = targetAddress.slice(0, -5);
  }

  const { location } = place.geometry;
  return {
    lat: location.lat instanceof Function ? location.lat() : location.lat,
    lon: location.lng instanceof Function ? location.lng() : location.lng,
    targetAddress,
    streetAddress: `${streetNumber?.short_name} ${street?.short_name}`,
    city: city?.short_name || 'all',
    city_long: city?.long_name || 'all',
    state: state?.short_name || 'all',
    zip: zip?.short_name || '',
    preciseLocation,
  };
};

const getMonthRanges = (date) => {
  let currentDate = new Date(date);
  const offset = currentDate.getTimezoneOffset();
  currentDate = new Date(currentDate.getTime() + offset * 60000);
  const currentFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const currentTo = new Date(currentFrom.getFullYear(), currentFrom.getMonth() + 1, 0);
  const prevMonthFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  const prevMonthTo = new Date(prevMonthFrom.getFullYear(), prevMonthFrom.getMonth() + 1, 0);
  const NextMonthFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
  const NextMonthTo = new Date(NextMonthFrom.getFullYear(), NextMonthFrom.getMonth() + 1, 0);

  const currentMonth = { from: currentFrom, to: currentTo };
  const prevMonth = { from: prevMonthFrom, to: prevMonthTo };
  const nextMonth = { from: NextMonthFrom, to: NextMonthTo };
  return {
    currentMonth,
    prevMonth,
    nextMonth,
  };
};

export default getMonthRanges;

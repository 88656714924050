export default (user, fallback) => {
  const { firstName, lastName } = user || {};
  if (firstName && !lastName) {
    return firstName;
  }

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return fallback;
};

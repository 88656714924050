export default (array, { joiner, lastJoiner } = {}) => {
  const definedValuesArray = array.filter(Boolean);
  if (!definedValuesArray.length) {
    return '';
  }
  if (definedValuesArray.length === 1) {
    return definedValuesArray[0];
  }
  const lastItem = definedValuesArray.pop();
  return `${definedValuesArray.map((s) => s.trim()).join(joiner || ', ')}${lastJoiner || ' & '}${lastItem}`;
};

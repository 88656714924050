export default (markers) => {
  if (!markers.length) {
    return undefined;
  }
  let latMax = Math.max(...markers.map((m) => m.latitude));
  let lonMax = Math.max(...markers.map((m) => m.longitude));
  let latMin = Math.min(...markers.map((m) => m.latitude));
  let lonMin = Math.min(...markers.map((m) => m.longitude));

  const padding = {
    lat: (latMax - latMin) / 50,
    lon: (lonMax - lonMin) / 50,
  };

  const treshold = 0.005;

  if (Math.abs(latMax - latMin) < treshold) {
    const center = (latMax + latMin) / 2;
    latMax = center + treshold;
    latMin = center - treshold;
  }

  if (Math.abs(lonMax - lonMin) < treshold) {
    const center = (lonMax + lonMin) / 2;
    lonMax = center + treshold;
    lonMin = center - treshold;
  }

  return {
    neLat: latMax + padding.lat,
    neLon: lonMax + padding.lon,
    swLat: latMin - padding.lat,
    swLon: lonMin - padding.lon,
  };
};

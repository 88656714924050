import MobileDetect from 'mobile-detect';
//  based on https://stackoverflow.com/a/9851769

export default {
  isSafari() { // Safari 3.0+ "[object HTMLElementConstructor]"
    return /constructor/i.test(window.HTMLElement) || (((p) => p.toString() === '[object SafariRemoteNotification]')(!window.safari || (typeof safari !== 'undefined' && window.safari.pushNotification)));
  },
  isFirefox() { // Firefox 1.0+
    return typeof InstallTrigger !== 'undefined';
  },
  isChromium() { // Chrome
    return !!window.chrome;
  },
  isOpera() { // Opera 8.0+
    return (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  },
  isIE() { // Internet Explorer 6-11
    return /* @cc_on!@ */false || !!document.documentMode;
  },
  idEdge() { // Edge 20+
    return !this.isIE && !!window.StyleMedia;
  },
  isEdgeChromium() {
    return this.isChromium && (navigator.userAgent.indexOf('Edg') !== -1);
  },
  isBlink() {
    return (this.isChromium || this.isOpera) && !!window.CSS;
  },
  isMobileDevice() { // based on: https://stackoverflow.com/a/58171659
    if ('maxTouchPoints' in navigator) {
      return navigator.maxTouchPoints > 0;
    }
    if ('msMaxTouchPoints' in navigator) {
      return navigator.msMaxTouchPoints > 0;
    }

    const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      return !!mQ.matches;
    }

    if ('orientation' in window) {
      return true; // deprecated, but good fallback
    }

    // Only as a last resort, fall back to user agent sniffing
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobileDetected = Object.isNotNull(md.mobile())
     || Object.isNotNull(md.phone())
     || Object.isNotNull(md.tablet());

    return md.isPhoneSized(600) || isMobileDetected;
  },
};

export default (from, to) => {
  const fromTrimmed = `${from}`.trim();
  const toTrimmed = `${to}`.trim();

  if (fromTrimmed === toTrimmed) {
    return fromTrimmed;
  }
  if ([fromTrimmed, toTrimmed].includes(undefined) || [fromTrimmed, toTrimmed].includes('')) {
    return from || to;
  }
  return `${fromTrimmed}–${toTrimmed}`;
};

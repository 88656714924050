import getStringFromArray from './getStringFromArray';

export default ({
  state,
  zip,
  city,
  address,
}) => {
  const stateZip = getStringFromArray([state, zip], { lastJoiner: ' ' });
  const addressCity = getStringFromArray([address, city], { lastJoiner: ', ' });
  return getStringFromArray([addressCity, stateZip], { lastJoiner: ', ' });
};

import { getDaysInMonthArray } from './getDaysInMonthArray';

const paymentWeeklyOptions = () => [
  {
    label: 'Monday',
    labelShort: 'Mon',
    value: '1',
  },
  {
    label: 'Tuesday',
    labelShort: 'Tue',
    value: '2',
  },
  {
    label: 'Wednesday',
    labelShort: 'Wed',
    value: '3',
  },
  {
    label: 'Thursday',
    labelShort: 'Thu',
    value: '4',
  },
  {
    label: 'Friday',
    labelShort: 'Fri',
    value: '5',
  },
];

const paymentConvertNumberToDayOfWeek = (number) => paymentWeeklyOptions()
  .find((option) => Number(option.value) === Number(number)).labelShort;

const paymentMonthlyOptions = () => getDaysInMonthArray()
  .map((item, index) => ({ label: item, value: index + 1 }));

export {
  paymentMonthlyOptions,
  paymentWeeklyOptions,
  paymentConvertNumberToDayOfWeek,
};

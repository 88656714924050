import toCamelCase from './toCamelCase';

export default (array = [], target = {}, key = 'id') => {
  const dictionary = { ...target };
  array.forEach((item) => {
    if (dictionary[item[key]] !== undefined) {
      dictionary[item[key]] = {
        ...dictionary[item[key]],
        ...toCamelCase(item),
      };
    } else {
      dictionary[item[key]] = toCamelCase(item);
    }
  });
  return dictionary;
};

const getDaySuffix = (day) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  if (day % 10 >= 1 && day % 10 <= 3 && !(day >= 11 && day <= 13)) {
    return suffixes[day % 10]; // 1st, 2nd, 3rd
  }
  return suffixes[0]; // th
};

const getDaysInMonthArray = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const daysArray = [];
  // eslint-disable-next-line no-plusplus
  for (let day = 1; day <= daysInMonth; day++) {
    if (day === 1) {
      daysArray.push(' First of month');
    } else {
      daysArray.push(`${day}${getDaySuffix(day)} of month`);
    }
  }
  return daysArray;
};

export {
  getDaySuffix,
  getDaysInMonthArray,
};

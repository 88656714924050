import moment from 'moment-timezone';

function format12HourTo24Hour(time12h) {
  return moment(time12h, 'h:mm a').format('HH:mm');
}

function format24HourTo12Hour(time24h) {
  return moment(time24h, 'HH:mm').format('h:mm a');
}

function isValidTimeRange({ from, to }) {
  return (from && to) || (!to && !from);
}

function minutesFromMidnightToTime(minutesFromMidnight) {
  const hours = Math.floor(minutesFromMidnight / 60);
  const mins = minutesFromMidnight % 60;

  const hoursFormatted = String(hours).padStart(2, '0');
  const minutesFormatted = String(mins).padStart(2, '0');
  return format24HourTo12Hour(`${hoursFormatted}:${minutesFormatted}`);
}

function timeToMinutesFromMidnight(time) {
  const timeObject = moment(time, 'hh:mm a');

  return timeObject.hours() * 60 + timeObject.minutes();
}

export {
  format12HourTo24Hour,
  format24HourTo12Hour,
  isValidTimeRange,
  minutesFromMidnightToTime,
  timeToMinutesFromMidnight,
};

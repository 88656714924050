import moment from 'moment-timezone';

export default (tour, timezone) => {
  const startTime = moment(tour.startTime).tz(timezone);
  const endTime = moment(tour.endTime).tz(timezone);

  if (startTime.format('a') === endTime.format('a')) {
    return `${startTime.format('h:mm')} - ${endTime.format('h:mma')}`;
  }

  return `${startTime.format('h:mma')} - ${endTime.format('h:mma')}`;
};

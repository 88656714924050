function subtract(minuend, subtrahend, precision = 2) {
  const multiplicand = 10 ** precision;
  const minuend2 = Math.floor(minuend * multiplicand);
  const subtrahend2 = Math.floor(subtrahend * multiplicand);
  return (minuend2 - subtrahend2) / multiplicand;
}

function add(augend, addend, precision = 2) {
  const multiplicand = 10 ** precision;
  const augend2 = Math.floor(augend * multiplicand);
  const addend2 = Math.floor(addend * multiplicand);
  return (augend2 + addend2) / multiplicand;
}

export { subtract, add };

const MS_PER_DAY = 24 * 60 * 60 * 1000;
const MS_PER_BIWEEK = 14 * MS_PER_DAY;
const DAY_OFFSET = 1; // Monday as the start of the week

function startOfBiweek(date) {
  const day = date.getDay();
  const diff = (day === 0 ? -6 : DAY_OFFSET - day); // Adjust when the day is Sunday (0)
  return new Date(date.getTime() + diff * MS_PER_DAY);
}

function endOfBiweek(date) {
  const start = startOfBiweek(date);
  return new Date(start.getTime() + 13 * MS_PER_DAY);
}

function getRangeForBiweek(date, offset) {
  let current = new Date(date);
  const zoneOffset = current.getTimezoneOffset();
  current = new Date(current.getTime() + zoneOffset * 60000);
  const start = startOfBiweek(new Date(current.getTime() + offset * MS_PER_BIWEEK));
  const end = endOfBiweek(start);
  return { start, end };
}

const getBiWeekRanges = (date) => {
  const currentBiweek = getRangeForBiweek(date, 0);
  const previousBiweek = getRangeForBiweek(date, -1);
  const nextBiweek = getRangeForBiweek(date, 1);

  return {
    currentBiweek,
    previousBiweek,
    nextBiweek,
  };
};

export default getBiWeekRanges;

import snakeCase from 'lodash/snakeCase';

const isObject = (value) => typeof value === 'object' && value !== null && !(value instanceof RegExp) && !(value instanceof Error) && !(value instanceof Date);

const toSnakeCase = (input) => {
  if (!isObject(input)) {
    return input;
  }

  if (Array.isArray(input)) {
    return Object.keys(input).map((key) => toSnakeCase(input[key]));
  }

  const keys = Object.keys(input);
  const snakeCasedObj = {};

  keys.forEach((key) => {
    snakeCasedObj[snakeCase(key)] = toSnakeCase(input[key]);
  });

  return snakeCasedObj;
};

export default toSnakeCase;

import axios from './axios';

const flags = {};

export default async (featureFlag) => new Promise((resolve) => {
  const featureFlagExists = flags[featureFlag];
  if (featureFlagExists) {
    resolve(featureFlagExists);
    return;
  }

  try {
    axios.get('/api/v1/users/feature_flag_enabled', {
      params: {
        feature_flag: featureFlag,
      },
    }).then((res) => {
      flags[featureFlag] = res.data.enabled;
      resolve(res.data.enabled);
    });
  } catch {
    resolve(false);
  }
});

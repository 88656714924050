import camelCase from 'lodash/camelCase';

const isObject = (value) => typeof value === 'object' && value !== null && !(value instanceof RegExp) && !(value instanceof Error) && !(value instanceof Date);

const toCamelCase = (input) => {
  if (!isObject(input)) {
    return input;
  }

  if (Array.isArray(input)) {
    return Object.keys(input).map((key) => toCamelCase(input[key]));
  }

  const keys = Object.keys(input);
  const camelCasedObj = {};

  keys.forEach((key) => {
    camelCasedObj[camelCase(key)] = toCamelCase(input[key]);
  });

  return camelCasedObj;
};

export default toCamelCase;

export default (birthdate) => {
  const now = new Date();
  let months = 12 * (now.getUTCFullYear() - birthdate.getUTCFullYear());
  months += (now.getUTCMonth() - birthdate.getUTCMonth());
  if (now.getUTCDate() < birthdate.getUTCDate()) {
    months -= 1;
  }

  return months;
};

function isObject(value) {
  return typeof value === 'object' && value !== null && !(value instanceof RegExp) && !(value instanceof Error) && !(value instanceof Date);
}

function isUndefined(value) {
  if (value === undefined || value === null) {
    return true;
  }

  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }

  return false;
}

const clearEmptyValue = (
  input,
  options = { removeUndefinedFromArray: false, emptyObjectAsUndefined: false },
) => {
  if (isUndefined(input)) {
    return undefined;
  }

  if (Array.isArray(input)) {
    const clearArray = input.map((item) => clearEmptyValue(item));

    if (options.removeUndefinedFromArray) {
      return clearArray.filter((item) => item !== undefined);
    }

    return clearArray;
  }

  if (isObject(input)) {
    const keys = Object.keys(input);

    if (options.emptyObjectAsUndefined) {
      const values = Object.values(input);
      if (values.every((v) => v === undefined) || !values.length) {
        return undefined;
      }
    }

    const clearedObj = {};

    keys.forEach((key) => {
      if (!isUndefined(input[key])) {
        clearedObj[key] = clearEmptyValue(input[key]);
      }
    });

    return clearedObj;
  }

  return input;
};

export default clearEmptyValue;

import roundAmount from './roundAmount';
import getRange from './getRange';

const dollar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

export function getDollarFormat(amountInCents) {
  return dollar.format(roundAmount(Number(amountInCents || 0) / 100));
}

export function getRangeInDollarFormat(from, to) {
  const isToUndefined = [null, undefined].includes(to);
  const isFromUndefined = [null, undefined].includes(from);

  if (isToUndefined && isFromUndefined) {
    return undefined;
  }

  if (isToUndefined || isFromUndefined) {
    return getDollarFormat(to || from);
  }

  if (to === from) {
    return getDollarFormat(to);
  }

  const toWithoutUnit = getDollarFormat(to).substring(1);
  return getRange(getDollarFormat(from), toWithoutUnit);
}

export function getDollarFormatNoZeros(amountInCents) {
  const amount = getDollarFormat(amountInCents);
  return (amount.endsWith('00')) ? amount.slice(0, -3) : amount;
}

import getRange from './getRange';
import getStringFromArray from './getStringFromArray';

const daysMapping = {
  mon: 0,
  tue: 1,
  wed: 2,
  thu: 3,
  fri: 4,
  sat: 5,
  sun: 6,
};

const reverseDaysMapping = {
  0: 'Monday',
  1: 'Tuesday',
  2: 'Wednesday',
  3: 'Thursday',
  4: 'Friday',
  5: 'Saturday',
  6: 'Sunday',
};

export default (days, shortName, lastJoiner = ', ') => {
  if (!days || !days.length) {
    return undefined;
  }
  const daysAsNumbers = days.map((d) => daysMapping[d]).sort();
  const ranges = daysAsNumbers.reduce((acc, curr, i) => {
    if (i === 0) {
      return [{ from: curr, to: curr }];
    }
    const currRange = acc[acc.length - 1];
    if (currRange.to === curr - 1) {
      currRange.to = curr;
      return acc;
    }
    return [...acc, { from: curr, to: curr }];
  }, []);

  return getStringFromArray(
    ranges.map((r) => {
      if (shortName) {
        return getRange(reverseDaysMapping[r.from][0], reverseDaysMapping[r.to][0]);
      }
      return getRange(reverseDaysMapping[r.from], reverseDaysMapping[r.to]);
    }),
    { lastJoiner },
  );
};

export default (paymentMethod) => {
  if (!paymentMethod) {
    return '';
  }

  const isCard = paymentMethod.paymentMethodType.toLowerCase() === 'card';
  const cardName = isCard ? `${paymentMethod.fsa ? 'FSA ' : ''}${`${paymentMethod.cardType.charAt(0).toUpperCase() + paymentMethod.cardType.slice(1)} ` || ''}` : undefined;
  const bankAccountName = isCard ? undefined : `${`${paymentMethod.name} ` || ''}`;

  return `${cardName || bankAccountName} *${paymentMethod.last4}`;
};

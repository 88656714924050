const MS_PER_DAY = 24 * 60 * 60 * 1000;
const DAY_OFFSET = 1; // Monday

function startOfWeek(date) {
  const day = date.getDay();
  const diff = (day === 0 ? -6 : DAY_OFFSET - day); // Adjust when the day is Sunday (0)
  return new Date(date.getTime() + diff * MS_PER_DAY);
}

function endOfWeek(date) {
  const start = startOfWeek(date);
  return new Date(start.getTime() + 6 * MS_PER_DAY);
}

function getRangeForWeek(date, offset) {
  let current = new Date(date);
  const zoneOffset = current.getTimezoneOffset();
  current = new Date(current.getTime() + zoneOffset * 60000);
  const start = startOfWeek(new Date(current.getTime() + offset * 7 * MS_PER_DAY));
  const end = endOfWeek(start);
  return { start, end };
}

const getWeekRanges = (date) => {
  const currentWeek = getRangeForWeek(date, 0);
  const previousWeek = getRangeForWeek(date, -1);
  const nextWeek = getRangeForWeek(date, 1);

  return {
    currentWeek,
    previousWeek,
    nextWeek,
  };
};

export default getWeekRanges;

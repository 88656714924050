import getEnvVar from '@utils/getEnvVar';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';

export default () => {
  if (getEnvVar('VUE_APP_ENVIRONMENT') !== 'development') {
    Sentry.init({
      Vue,
      dsn: getEnvVar('SENTRY_VUE_DSN'),
      environment: getEnvVar('VUE_APP_ENVIRONMENT'),
    });
  }
};

export const getMarkerRadiusInDegrees = (dimensionInPx = 1, dimensionInDegrees = 1) => {
  const markerRadiusInPx = 15;
  return Math.abs((markerRadiusInPx * dimensionInDegrees) / dimensionInPx);
};

// eslint-disable-next-line max-len
const isChangeNegligible = (oldValue, newValue, dimensionInPx = 1, dimensionInDegrees = 1, scale = 1) => {
  const coordinateChange = Math.abs(newValue - oldValue);
  const changeTolerance = getMarkerRadiusInDegrees(dimensionInPx, dimensionInDegrees) * scale;
  return coordinateChange < changeTolerance;
};

export default ({
  oldBounds, newBounds, oldDimensions, newDimensions, scale,
}) => {
  // check if the dimensions change is caused by os menu bar
  if (!newDimensions.width || !newDimensions.height) return false;

  if (oldDimensions) {
    const heightChange = Math.abs(newDimensions.height - oldDimensions.height);
    if (oldDimensions.width === newDimensions.width && heightChange < 100
    && oldBounds.swLon === newBounds.swLon
    && oldBounds.neLon === newBounds.neLon) {
      return false;
    }

    const isWidthChangeNegligible = isChangeNegligible(
      oldDimensions.width,
      newDimensions.width,
    );

    const isHeightChangeNegligible = isChangeNegligible(
      oldDimensions.height,
      newDimensions.height,
    );

    if (!isWidthChangeNegligible || !isHeightChangeNegligible) {
      return true;
    }
  }

  const isLatChangeNegligible = isChangeNegligible(
    oldBounds.neLat,
    newBounds.neLat,
    newDimensions.width,
    Math.abs(newBounds.neLat - newBounds.swLat),
    scale,
  );

  const isLonChangeNegligible = isChangeNegligible(
    oldBounds.neLon,
    newBounds.neLon,
    newDimensions.height,
    Math.abs(newBounds.neLon - newBounds.swLon),
    scale,
  );

  return !isLatChangeNegligible
    || !isLonChangeNegligible;
};
